<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <card
            header-classes="d-flex justify-content-between"
            body-classes="table-full-width table-responsive"
          >
            <template slot="header">
              <h4 class="card-title m-0 pt-2">Lista de Granjas</h4>
              <div class="d-flex justify-content-between">
                <div>
                  <button
                    class="btn btn-primary btn-fill mr-2"
                    @click="handleCrear"
                  >
                    Crear Granja
                  </button>
                </div>
              </div>
            </template>
            <div
              v-if="granjas.length"
              class="table-hover table-striped table-bordered"
            >
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th class="text-center">N°</th>
                    <th class="text-center">Nombre</th>
                    <th class="text-center">Ubicación</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="granja in granjas" :key="granja.id">
                    <td>{{ granja.id }}</td>
                    <td>{{ granja.nombre }}</td>
                    <td>{{ granja.ubicacion }}</td>
                    <td class="text-center">
                      <button
                        class="btn btn-primary btn-sm mr-2"
                        @click="verGranja(granja.id)"
                      >
                        Ver
                      </button>
                      <button
                        class="btn btn-warning btn-sm"
                        @click="editarGranja(granja.id)"
                      >
                        Editar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="clearfix" />
            </div>
            <div v-else class="text-center">
              <p>No hay granjas disponibles.</p>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Card from "../components/Cards/Card.vue";

export default {
  components: { Card },
  data() {
    return {
      granjas: [],
    };
  },
  mounted() {
    this.cargarGranjas();
  },
  methods: {
    async cargarGranjas() {
      try {
        const { data } = await axios.get("/api/granja");
        this.granjas = data;
      } catch (error) {
        console.error("Hubo un error al obtener las granjas:", error);
      }
    },
    handleCrear() {
      this.$router.push({
        name: "CrearGranja",
      });
    },
    verGranja(granjaId) {
      this.$router.push({
        name: "DetalleGranja",
        params: { id: granjaId },
      });
    },
    editarGranja(granjaId) {
      this.$router.push({
        name: "EditarGranja",
        params: { id: granjaId },
      });
    },
  },
};
</script>
