<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <card :title="cardTitle">
            <ValidationObserver v-slot="{ invalid, handleSubmit }">
              <form @submit.prevent="handleSubmit(guardar)">
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Semana"
                      rules="required|not_selected"
                    >
                      <base-input label="Semana">
                        <select
                          v-model="datosPesaje.semana_id"
                          class="custom-select"
                          :disabled="isEditMode"
                          @change="selectSemana"
                        >
                          <option value="-1">(Seleccionar Semana)</option>
                          <option
                            v-for="semana in semanas"
                            :key="semana.id"
                            :value="semana.id"
                            :disabled="!semana.habilitada"
                          >
                            {{ mostrarNombreSemana(semana.numero) }}
                          </option>
                        </select>
                      </base-input>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Fecha de pesaje"
                      rules="required"
                    >
                      <base-input
                        v-model="datosPesaje.fecha_pesaje"
                        type="date"
                        label="Fecha de pesaje"
                        :disabled="isEditMode"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div v-if="sampleSize !== null">
                  <h5>Tamaño de Muestra: {{ sampleSize }}</h5>
                  <div v-for="(seccion, sIndex) in weights" :key="sIndex">
                    <h4>
                      Ingrese los pesajes Sección
                      {{ seccion.seccion }}:
                    </h4>
                    <p>
                      Tamaño de muestra:
                      {{ seccion.pesos.length }}
                    </p>

                    <div class="row mr-3">
                      <div
                        v-for="(weight, wIndex) in seccion.pesos"
                        :key="wIndex"
                        class="col-md-2 mb-3"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          :name="
                            'Pesaje Sección ' +
                            seccion.seccion +
                            ' ' +
                            (wIndex + 1)
                          "
                          rules="required|numeric|min_value:1"
                        >
                          <div class="row ml-3">
                            <div class="col-md-12">
                              <label :for="'weight-' + sIndex + '-' + wIndex"
                                >Pesaje {{ wIndex + 1 }}</label
                              >
                            </div>
                            <div class="col-md-12">
                              <input
                                :id="'weight-' + sIndex + '-' + wIndex"
                                v-model="weight.peso"
                                type="number"
                                class="form-control"
                                min="0"
                                required
                              />
                              <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                          </div>
                        </ValidationProvider>
                      </div>
                    </div>

                    <button
                      class="btn btn-primary"
                      style="margin-top: 10px"
                      @click.prevent="addPeso(sIndex)"
                    >
                      Añadir más pesajes para Sección
                      {{ seccion.seccion }}
                    </button>
                  </div>
                </div>

                <div class="text-center">
                  <button
                    type="submit"
                    class="btn btn-info btn-fill float-right"
                    :disabled="loading || invalid"
                  >
                    Guardar
                  </button>
                </div>
                <div class="clearfix" />
              </form>
            </ValidationObserver>
            <div v-if="loading" class="loading-indicator">Cargando...</div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Card from "../components/Cards/Card.vue";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import es from "vee-validate/dist/locale/es.json";
import Swal from "sweetalert2";

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

extend("not_selected", {
  validate: (value) => value !== "-1",
  message: "Debe seleccionar una opción válida.",
});

localize("es", es);

export default {
  name: "CrearPesaje",
  components: { Card, ValidationProvider, ValidationObserver },
  props: {
    galponLoteId: {
      type: String,
      default: "",
    },
    pesoId: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      semanas: [],
      datosPesaje: {
        semana_id: -1,
        fecha_pesaje: "",
      },
      galpon: {},
      weights: [],
      sampleSize: null,
      loading: false,
      isEditMode: !!this.pesoId,
    };
  },
  computed: {
    cardTitle() {
      return this.isEditMode
        ? `Actualizar pesaje - Galpón No. ${this.galpon.numero}`
        : `Ingreso pesaje - Galpón No. ${this.galpon.numero}`;
    },
    castedGalponLoteId() {
      return parseInt(this.galponLoteId);
    },
  },

  mounted() {
    this.cargarSemanas();
    this.cargarGalpon();
    this.isEditMode && this.cargarPesaje();
  },
  methods: {
    mostrarNombreSemana(numero) {
      return numero === 0 ? "Día 1" : `Semana ${numero}`;
    },
    async cargarSemanas() {
      try {
        const { data } = await axios.get(
          "api/peso/semana/" + this.galponLoteId,
        );
        this.semanas = data.map((semana) => ({
          ...semana,
          habilitada: semana.habilitada || false,
        }));
        if (!this.isEditMode) {
          const ultimaSemanaHabilitada = this.semanas
            .filter((semana) => semana.habilitada)
            .pop();

          if (ultimaSemanaHabilitada) {
            this.datosPesaje.semana_id = ultimaSemanaHabilitada.id;
            this.selectSemana();
          }
        }
      } catch (error) {
        console.error("Error al cargar las semanas:", error);
      }
    },

    async cargarGalpon() {
      try {
        const { data } = await axios.get(
          `/api/galpon/byGalponLoteId/${this.castedGalponLoteId}`,
        );
        this.galpon = data.galpon;
        this.weights = data.weights.map((seccion) => ({
          seccion: seccion.seccion,
          pesos: Array.from({ length: seccion.cantidad }, () => ({
            peso: null,
          })),
        }));
        this.sampleSize = data.sampleSize;
      } catch (error) {
        console.error("Error al cargar el galpón:", error);
      }
    },

    async cargarPesaje() {
      try {
        const { data } = await axios.get(`/api/peso/pesos/${this.pesoId}`);

        this.datosPesaje.semana_id = data.semana_id;
        this.datosPesaje.fecha_pesaje = data.fecha_pesaje;

        // Inicializar pesos por sección
        const pesosPorSeccion = {};

        if (Array.isArray(data.ave_pesos)) {
          data.ave_pesos.forEach((peso) => {
            if (!pesosPorSeccion[peso.seccion]) {
              pesosPorSeccion[peso.seccion] = [];
            }
            pesosPorSeccion[peso.seccion].push({
              id: peso.id,
              peso: peso.peso,
              peso_dato_id: peso.peso_dato_id,
            });
          });
        }

        // Filtrar y asignar pesos a las secciones
        this.weights.forEach((seccion) => {
          if (seccion.seccion) {
            const pesos = pesosPorSeccion[seccion.seccion] || [];
            seccion.pesos = pesos.map((peso) => ({
              id: peso.id,
              peso: peso.peso,
              peso_dato_id: peso.peso_dato_id,
            }));
          }
        });
      } catch (error) {
        console.error("Error al cargar el pesaje:", error);
        if (error.response) {
          console.error("Respuesta del servidor:", error.response.data);
        }
        Swal.fire({
          title: "Error",
          text: "Hubo un problema al cargar el pesaje. Por favor, intente de nuevo.",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    },

    async guardar() {
      const { semana_id: semanaId, fecha_pesaje: fechaPesaje } =
        this.datosPesaje;
      const data = {
        semana_id: semanaId,
        fecha_pesaje: fechaPesaje,
        pesos: this.weights.reduce((accumulator, seccion) => {
          seccion.pesos.forEach((weight) => {
            accumulator.push({
              seccion: seccion.seccion,
              peso: weight.peso,
            });
          });
          return accumulator;
        }, []),
      };

      if (this.isEditMode) {
        data.pesoId = this.pesoId;
      }

      this.loading = true;
      try {
        await axios.post("/api/peso", data);
        Swal.fire({
          title: "Éxito",
          text: this.isEditMode
            ? "Pesaje actualizado correctamente"
            : "Pesaje guardado correctamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        }).then(() => {
          this.$router.push({ name: "Overview" });
        });
      } catch (error) {
        console.error("Error al guardar el pesaje:", error);
        if (error.response) {
          console.error("Respuesta del servidor:", error.response.data);
        }
        Swal.fire({
          title: "Error",
          text: "Hubo un problema al guardar el pesaje. Por favor, intente de nuevo.",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      } finally {
        this.loading = false;
      }
    },

    selectSemana() {
      const selectedSemana = this.semanas.find(
        (semana) => semana.id === this.datosPesaje.semana_id,
      );
      if (selectedSemana) {
        let fechaBase;
        if (selectedSemana.numero === 0) {
          fechaBase = selectedSemana.fecha;
        } else {
          // Obtener la fecha del último día de la semana
          const ultimaFechaSemana =
            selectedSemana.dias_semana[selectedSemana.dias_semana.length - 1]
              ?.fecha;
          if (ultimaFechaSemana) {
            const fechaUltima = new Date(ultimaFechaSemana);

            fechaUltima.setDate(fechaUltima.getDate() + 1);
            // Formatear la nueva fecha como "yyyy-MM-dd"
            fechaBase = fechaUltima.toISOString().split("T")[0];
          }
        }

        if (fechaBase) {
          this.datosPesaje.fecha_pesaje = fechaBase;
        }
      }
    },

    addPeso(sIndex) {
      const newWeight = { peso: null };
      this.weights[sIndex].pesos.push(newWeight);
    },
  },
};
</script>

<style>
.loading-indicator {
  text-align: center;
  padding: 20px;
  font-size: 1.5em;
  color: #333;
}
</style>
