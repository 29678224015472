import DashboardLayout from "../layout/DashboardLayout.vue";
// GeneralViews
import NotFound from "../pages/NotFoundPage.vue";

// Admin pages
import Overview from "src/pages/Overview.vue";
import IngresoDiario from "src/pages/IngresoDiario.vue";
import Lote from "src/pages/Lote.vue";
import CrearLote from "src/pages/CrearLote.vue";
import Pesaje from "src/pages/Pesaje.vue";
import CrearPesaje from "src/pages/CrearPesaje.vue";
import EditarLote from "src/pages/EditarLote.vue";
import Granja from "src/pages/Granja.vue";
import CrearGranja from "src/pages/CrearGranja.vue";
import EditarGranja from "src/pages/EditarGranja.vue";
import DetalleGranja from "src/pages/DetalleGranja.vue";
import TableList from "src/pages/TableList.vue";
import Typography from "src/pages/Typography.vue";
import Icons from "src/pages/Icons.vue";
import Maps from "src/pages/Maps.vue";
import DetalleLote from "src/pages/DetalleLote.vue";
import Cliente from "src/pages/Cliente.vue";
import DetalleCliente from "src/pages/DetalleCliente.vue";
import EditarCliente from "src/pages/EditarCliente.vue";
import CrearCliente from "src/pages/CrearCliente.vue";
import Notifications from "src/pages/Notifications.vue";
import Upgrade from "src/pages/Upgrade.vue";
import LoginLayout from "src/layout/LoginLayout.vue";
import Login from "src/pages/Login.vue";
import UsuariosCliente from "src/pages/UsuariosCliente.vue";
import CrearUsuario from "src/pages/CrearUsuario.vue";
import EditarUsuario from "src/pages/EditarUsuario.vue";

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/admin/overview",
  },
  {
    path: "/admin",
    component: DashboardLayout,
    children: [
      {
        path: "overview",
        name: "Overview",
        component: Overview,
      },
      {
        path: "ingreso/:galponLoteId/:tipo",
        name: "Ingreso",
        component: IngresoDiario,
        props: true,
      },
      {
        path: "lote/crear",
        name: "CrearLote",
        component: CrearLote,
        props: true,
      },
      {
        path: "granja/crear",
        name: "CrearGranja",
        component: CrearGranja,
        props: true,
      },
      {
        path: "pesaje",
        name: "Pesaje",
        component: Pesaje,
        props: true,
      },
      {
        path: "lote/:id/editar",
        name: "EditarLote",
        component: EditarLote,
        props: true,
      },
      {
        path: "granja/:id/editar",
        name: "EditarGranja",
        component: EditarGranja,
        props: true,
      },
      {
        path: "crearpesaje/:galponLoteId/:pesoId?",
        name: "CrearPesaje",
        component: CrearPesaje,
        props: true,
      },
      {
        path: "lote/:id",
        name: "DetalleLote",
        component: DetalleLote,
      },
      {
        path: "granja/:id",
        name: "DetalleGranja",
        component: DetalleGranja,
      },
      {
        path: "lote",
        name: "Lote",
        component: Lote,
        props: true,
      },
      {
        path: "cliente",
        name: "Cliente",
        component: Cliente,
      },
      {
        path: "cliente/:id",
        name: "DetalleCliente",
        component: DetalleCliente,
        props: true,
      },
      {
        path: "cliente/crearCliente",
        name: "CrearCliente",
        component: CrearCliente,
      },
      {
        path: "cliente/:id/editar",
        name: "EditarCliente",
        component: EditarCliente,
        props: true,
      },
      {
        path: "usuarios/:id",
        name: "UsuariosCliente",
        component: UsuariosCliente,
        props: true,
      },
      {
        path: "usuario/crear/:id",
        name: "CrearUsuario",
        component: CrearUsuario,
        props: true,
      },
      {
        path: "usuario/editar/:usuarioId",
        name: "EditarUsuario",
        component: EditarUsuario,
        props: true,
      },
      {
        path: "granja",
        name: "Granja",
        component: Granja,
        props: true,
      },
      {
        path: "table-list",
        name: "Table List",
        component: TableList,
      },
      {
        path: "typography",
        name: "Typography",
        component: Typography,
      },
      {
        path: "icons",
        name: "Icons",
        component: Icons,
      },
      {
        path: "maps",
        name: "Maps",
        component: Maps,
      },
      {
        path: "notifications",
        name: "Notifications",
        component: Notifications,
      },
      {
        path: "upgrade",
        name: "Upgrade to PRO",
        component: Upgrade,
      },
    ],
  },
  {
    path: "/login",
    component: LoginLayout,
    children: [
      {
        path: "/",
        name: "Login",
        component: Login,
      },
    ],
  },
  { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
