<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <card
            title="Ingreso Creación/Granja"
            :sub-title="'Ingreso de datos para granja'"
          >
            <ValidationObserver v-slot="{ invalid, handleSubmit }">
              <form @submit.prevent="handleSubmit(guardar)">
                <div class="row">
                  <div class="col-md-6 ml-n2">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Nombre"
                      rules="required"
                    >
                      <base-input
                        v-model="datosGranja.nombre"
                        type="text"
                        label="Nombre"
                        placeholder="Nombre"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Ubicación"
                      rules="required"
                    >
                      <base-input
                        v-model="datosGranja.ubicacion"
                        type="text"
                        label="Ubicación"
                        placeholder="Ubicación"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Altura sobre nivel del mar"
                      rules="required"
                    >
                      <base-input label="Altura">
                        <select
                          v-model="datosGranja.altura_id"
                          class="custom-select"
                          @change="actualizarClima"
                        >
                          <option value="">(Seleccionar Altura)</option>
                          <option
                            v-for="altura in alturas"
                            :key="altura.id"
                            :value="altura.id"
                          >
                            {{ altura.altura }}
                          </option>
                        </select>
                      </base-input>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Tipo de Clima"
                      rules="required"
                    >
                      <base-input
                        v-model="datosGranja.tipo_clima"
                        type="text"
                        label="Tipo de Clima"
                        placeholder="Clima (ej. lluvioso)"
                        :readonly="true"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Programa de Oscurecimiento"
                      rules="required"
                    >
                      <base-input label="Programa Oscurecimiento">
                        <select
                          v-model="datosGranja.programaOscurecimiento_id"
                          class="custom-select"
                        >
                          <option value="">
                            (Seleccionar Programa Oscurecimiento)
                          </option>
                          <option
                            v-for="programa in programaOscurecimientos"
                            :key="programa.id"
                            :value="programa.id"
                          >
                            {{ programa.programa_oscurecimiento }}
                          </option>
                        </select>
                      </base-input>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Tipo de Alojamiento"
                      rules="required"
                    >
                      <base-input label="Alojamientos">
                        <select
                          v-model="datosGranja.alojamiento_id"
                          class="custom-select"
                        >
                          <option value="">(Seleccionar Alojamiento)</option>
                          <option
                            v-for="alojamiento in alojamientos"
                            :key="alojamiento.id"
                            :value="alojamiento.id"
                          >
                            {{ alojamiento.alojamiento }}
                          </option>
                        </select>
                      </base-input>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Tipo de Comederos"
                      rules="required"
                    >
                      <base-input label="Comederos">
                        <select
                          v-model="datosGranja.comedero_id"
                          class="custom-select"
                        >
                          <option value="">(Seleccionar Comedero)</option>
                          <option
                            v-for="comedero in comederos"
                            :key="comedero.id"
                            :value="comedero.id"
                          >
                            {{ comedero.comederos }}
                          </option>
                        </select>
                      </base-input>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Cantidad de Galpones"
                      rules="required|numeric|min_value:1"
                    >
                      <base-input
                        v-model="datosGranja.cantidad_galpones"
                        type="number"
                        label="Cantidad de Galpones"
                        placeholder="Cantidad de Galpones"
                        @input="generarSecciones"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div v-if="datosGranja.cantidad_galpones" class="row m-1">
                  <div
                    v-for="(seccion, index) in datosGranja.secciones"
                    :key="index"
                    class="col-md-2 mb-3"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      :name="'Sección ' + (index + 1)"
                      rules="required|numeric|min_value:1"
                    >
                      <base-input
                        v-model="datosGranja.secciones[index]"
                        type="number"
                        :label="'Secciones Galpon ' + (index + 1)"
                        placeholder="Cantidad de secciones galpon"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="text-center">
                  <button
                    type="submit"
                    class="btn btn-info btn-fill float-right"
                    :disabled="loading || invalid"
                  >
                    Guardar
                  </button>
                </div>
                <div class="clearfix" />
              </form>
            </ValidationObserver>
            <div v-if="loading" class="loading-indicator">Cargando...</div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Card from "../components/Cards/Card.vue";
import Swal from "sweetalert2";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import es from "vee-validate/dist/locale/es.json";

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

localize("es", es);

export default {
  name: "CrearGranja",
  components: { Card, ValidationProvider, ValidationObserver },
  data() {
    return {
      datosGranja: {
        nombre: "",
        ubicacion: "",
        cantidad_galpones: "",
        secciones: [],
        altura_id: "",
        programaOscurecimiento_id: "",
        alojamiento_id: "",
        comedero_id: "",
      },
      alturas: [],
      programaOscurecimientos: [],
      comederos: [],
      alojamientos: [],
      loading: false,
    };
  },
  watch: {
    "datosGranja.altura_id": "actualizarClima",
  },
  mounted() {
    this.CargarDatos();
  },
  methods: {
    async CargarDatos() {
      try {
        const { data } = await axios.get("/api/granja/datos-granja");
        this.programaOscurecimientos = data.programa_oscurecimiento;
        this.alturas = data.alturas;
        this.alojamientos = data.tipos_alojamiento;
        this.comederos = data.tipos_comedero;
      } catch (error) {
        console.error("Error al cargar los datos:", error);
        alert(
          "No se pudieron cargar los datos. Por favor, intenta nuevamente.",
        );
      }
    },

    actualizarClima() {
      const alturaSeleccionada = this.alturas.find(
        (altura) => altura.id === this.datosGranja.altura_id,
      );
      if (alturaSeleccionada) {
        this.datosGranja.tipo_clima = alturaSeleccionada.tipo_clima;
      }
    },

    generarSecciones() {
      const cantidad = this.datosGranja.cantidad_galpones;
      this.datosGranja.secciones = Array.from({ length: cantidad }, (_, i) => {
        return this.datosGranja.secciones[i] || "";
      });
    },
    async guardar() {
      this.loading = true;
      try {
        const data = {
          nombre: this.datosGranja.nombre,
          ubicacion: this.datosGranja.ubicacion,
          cantidad_galpones: this.datosGranja.cantidad_galpones,
          secciones: this.datosGranja.secciones,
          altura_id: this.datosGranja.altura_id,
          programaOscurecimiento_id: this.datosGranja.programaOscurecimiento_id,
          alojamiento_id: this.datosGranja.alojamiento_id,
          comedero_id: this.datosGranja.comedero_id,
        };
        await axios.post("/api/granja/CrearGranja", data);
        Swal.fire({
          icon: "success",
          title: "Éxito",
          text: "Granja creada correctamente.",
          confirmButtonText: "Aceptar",
        }).then(() => {
          this.$router.push({ name: "Granja" });
        });
      } catch (error) {
        console.error(
          "Error al guardar los datos:",
          error.response ? error.response.data : error.message,
        );
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "No se pudieron guardar los datos. Por favor, intenta nuevamente.",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.loading-indicator {
  text-align: center;
  padding: 20px;
  font-size: 1.5em;
  color: #333;
}
</style>
